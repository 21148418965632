import { Button } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useRouter } from 'next/router';

import theme from '../styles/theme';

interface ICompanyButtonProps {
  link: string;
  children: React.ReactNode;
}

const CompanyButton: FC<ICompanyButtonProps> = (props) => {
  const { link, children } = props;

  const router = useRouter();

  const onClick = useCallback(() => router.push(`${link}`), [link, router]);

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        boxShadow: theme.shadows[4],
        borderRadius: 4,
        height: 130,
        width: { xs: 300, sm: 400 },
        margin: theme.spacing(2),
        padding: theme.spacing(3),
      }}
    >
      {children}
    </Button>
  );
};

export default CompanyButton;
